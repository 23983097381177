/**
 * Accordion to have fold out content
 * Should have children of AccordionItem
 */

import * as Accordion from '@radix-ui/react-accordion';
import React, { ReactElement, ReactNode } from 'react';
import { FiChevronDown } from 'react-icons/fi';

import { CONFIG } from '@lib/constants';
import { mergeStyles } from '@lib/styles';

function Item({
  value,
  title,
  content,
  ...props
}: {
  value: string;
  title: string | ReactNode;
  content: ReactNode;
} & Record<string, any>): ReactElement {
  const { className, iconSize, ...rest } = props;
  return (
    <Accordion.Item value={value}>
      <Accordion.Header asChild>
        <div className="text-base">
          <Accordion.Trigger asChild>
            <div
              role="group"
              aria-roledescription="accordion"
              className={mergeStyles(
                className ?? '',
                'bg-tranparent group flex w-full cursor-pointer items-center justify-between border-none p-0 dark:text-white'
              )}
              {...rest}
            >
              {title}
              <FiChevronDown
                size={iconSize ? iconSize : CONFIG.defaultIconSize}
                className="group-data-[state=open]:rotate-180"
              />
            </div>
          </Accordion.Trigger>
        </div>
      </Accordion.Header>
      <Accordion.Content>{content}</Accordion.Content>
    </Accordion.Item>
  );
}

export function AccordionComponent({
  items,
  defaultOpen = true,
  openIndexes = [0],
  onChangeFunction,
  wrapperClass,
}: {
  items: { title: string | ReactNode; content: ReactNode, props?: Record<string, any> }[];
  defaultOpen?: boolean;
  openIndexes?: Array<number>;
  onChangeFunction?: () => void;
  wrapperClass?: string;
}): ReactElement {
  return defaultOpen ? (
    <Accordion.Root
      onValueChange={onChangeFunction}
      defaultValue={openIndexes.map((index) => index.toString())}
      type="multiple"
      className={wrapperClass}
    >
      {items.map((item, index) => (
        <Item
          key={index}
          value={index.toString()}
          title={item.title}
          content={item.content}
          {...item.props}
        />
      ))}
    </Accordion.Root>
  ) : (
    <Accordion.Root
      onValueChange={onChangeFunction}
      type="multiple"
      className={wrapperClass}
    >
      {items.map((item, index) => (
        <Item
          key={index}
          value={index.toString()}
          title={item.title}
          content={item.content}
          {...item.props}
        />
      ))}
    </Accordion.Root>
  );
}
