import dynamic from 'next/dynamic';
import React, { ReactElement, useState } from 'react';

import { AccordionComponent } from './Accordion';

const VerticalListDynamic = dynamic(() =>
  import('../List/VerticalList/VerticalList').then((mod) => mod.VerticalList)
);

/**
 * This is a modified version of the reach accordion component that conditionally
 * renders the children elements based on the collapsed state of the accordion
 * @param title string - the title for accordian panel
 * @param children React.ReactNode - children elements to render in a vertical list when the accordion is expanded
 * @returns ReactElement
 */
export function CustomConditionalAccordion({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}): ReactElement {
  const [showChildren, setShowChildren] = useState(false);
  return (
    <AccordionComponent
      defaultOpen={false}
      onChangeFunction={() => setShowChildren(!showChildren)}
      items={[
        {
          title: title,
          content: (
            <>
              {showChildren && (
                <VerticalListDynamic>{children}</VerticalListDynamic>
              )}
            </>
          ),
          props: {
            className: "gae-mmm-sch text-xl font-bold text-black my-2.5 mx-0",
            iconSize: "1.3em"
          }
        },
      ]}
    />
  );
}
