import { useEffect } from 'react';
import useSWR from 'swr';

import { isErrorResponse } from '@interfaces/BulkAPI';
import { Cart } from '@interfaces/ICart';
import { SWR_KEYS } from '@lib/constants';
import { logBreadcrumb } from '@lib/utils';
import { GetCartAPIResponse } from 'pages/api/cart';

export function useCart(): {
  cart: Cart | undefined;
  error: Error | null | undefined;
  isLoading: boolean;
} {
  const apiUrl = new URL('/api/cart', 'https://a');
  apiUrl.searchParams.set('include', 'line_items.physical_items.options');

  const fetcher = async (url: string) => {
    const res = await fetch(apiUrl.pathname + apiUrl.search);
    const serverRes: GetCartAPIResponse = await res.json();
    if (isErrorResponse(serverRes)) {
      const {
        error: { message, code },
      } = serverRes;
      if (code === 404 || code === 400) {
        return undefined;
      }
      const error = new Error('Unable to fetch cart');
      error.message = message.join('. ');
      throw error;
    }

    return serverRes.data;
  };

  // Get cart
  const { data, error, isLoading } = useSWR<Cart | undefined>(
    SWR_KEYS.cart,
    fetcher
  );

  useEffect(() => {
    logBreadcrumb({
      category: 'useCart',
      message: `received new cart data from BigCommerce`,
      level: 'info',
      data: data,
    });
  }, [data]);

  return { cart: data, error, isLoading };
}
